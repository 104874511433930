import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ResetPasswordComponent } from './reset-password.component'
import { Routes, RouterModule } from '@angular/router'
import { SharedModule } from '../shared/shared.module'
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap/modal'

const routes: Routes = [{ path: '', component: ResetPasswordComponent }]

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        RouterModule.forChild(routes)
    ],
    declarations: [ResetPasswordComponent]
})
export class ResetPasswordModule {}
