<div class="signin-container">
    <div class="signin-box">
        <div class="logo-container">
            <!-- <img class="logo" src="assets/images/tbd-logo.png" alt="" /> -->
        </div>

        <ng-container>
            <h4 class="text-center setP">Reset Password</h4>
            <!-- <p *ngIf="codeStatus === 'expire'" class="mb-5">Password code is expired</p> -->
            <form *ngIf="codeStatus === 'valid'" [formGroup]="resetPassForm" (ngSubmit)="proceedResetPass(resetPassForm, f)" #f="ngForm">
                <input type="hidden" formControlName="code" />
                <div class="row input-container">
                    <div class="col-md-12 form-inputs">
                        <label class="lab">Password</label>
                        <input type="password" class="form-control" formControlName="password" aria-describedby="emailHelp" placeholder="Password" [ngClass]="{ 'form-submitted': f.submitted }" />
                        <div *ngIf="ui.checkErrors(g.password, f)" class="errors">
                            <p *ngIf="g.password.errors?.required">Password is required</p>
                        </div>
                    </div>
                    <div class="col-md-12 form-inputs" style="margin-top: 10px">
                        <label class="lab">Confirm Password</label>
                        <input type="password" class="form-control" formControlName="password_confirmation" aria-describedby="emailHelp" placeholder="Confirm Password" [ngClass]="{ 'form-submitted': f.submitted }" />
                        <div *ngIf="ui.checkErrors(g.password_confirmation, f)" class="errors">
                            <p *ngIf="g.password_confirmation.errors?.required">Confirm password is required</p>
                        </div>
                    </div>
                </div>
                <div class="login-btn-container text-center">
                    <button [disabled]="inprogress" class="cg primary" [ngClass]="inprogress ? 'in-progress' : ''">Submit <span></span><span></span><span></span></button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="codeStatus == 'expire'">
            <div class="top-bar">
                <p class="text-center"></p>
            </div>
            <div class="successfully-registered">
                <h1 style="color: #084887">Your Code is expired Check your Email</h1>
            </div>
        </ng-container>
    </div>
</div>
